import { showNotificationWithTTL } from "actions/notifications"
import addFilteringScheduleCall from "api/filtering-schedules/addFilteringScheduleCall"
import loadFilteringSchedulesCall from "api/filtering-schedules/loadFilteringSchedulesCall"
import updateFilteringScheduleCall from "api/filtering-schedules/updateFilteringScheduleCall"
import {
  actionCreatorFetch,
  actionCreatorSuccess,
  actionCreatorFailure,
} from "utils/actionCreatorRequest"
import {
  LOAD_FILTERING_SCHEDULES,
  SUBMITTING_FILTERING_SCHEDULE,
  AFTER_CREATE_FILTERING_SCHEDULE,
  AFTER_UPDATE_FILTERING_SCHEDULE,
  AFTER_REMOVE_FILTERING_SCHEDULE,
  UPDATE_FILTERING_SCHEDULE_SEARCH_TEXT,
  UPDATE_FILTERING_SCHEDULE_STATUS_FILTER,
  UPDATE_ADD_NEW_SCHEDULE,
  UPDATE_EDIT_EXISTING_SCHEDULE,
  UPDATE_DELETE_EXISTING_SCHEDULE,
  UPDATE_SELECTED_ID,
} from "action-types/filteringSchedules"
import createUrl from "utils/createUrl"

export const loadFilteringSchedulesRequest = actionCreatorFetch(
  LOAD_FILTERING_SCHEDULES,
)

export const loadFilteringSchedulesSuccess = actionCreatorSuccess(
  LOAD_FILTERING_SCHEDULES,
)

export const loadFilteringSchedulesFailure = actionCreatorFailure(
  LOAD_FILTERING_SCHEDULES,
)

export const submittingFilteringSchedule = (payload) => ({
  type: SUBMITTING_FILTERING_SCHEDULE,
  payload,
})

export const afterCreateFilteringSchedule = (filteringSchedule) => ({
  type: AFTER_CREATE_FILTERING_SCHEDULE,
  payload: filteringSchedule,
})

export const afterUpdateFilteringSchedule = (filteringSchedule) => ({
  type: AFTER_UPDATE_FILTERING_SCHEDULE,
  payload: filteringSchedule,
})

export const afterRemoveFilteringSchedule = (filteringSchedule) => ({
  type: AFTER_REMOVE_FILTERING_SCHEDULE,
  payload: filteringSchedule,
})

export const updateFilteringScheduleSearchText = (searchText) => ({
  type: UPDATE_FILTERING_SCHEDULE_SEARCH_TEXT,
  payload: searchText,
})

export const updateFilteringScheduleStatusFilter = (status) => ({
  type: UPDATE_FILTERING_SCHEDULE_STATUS_FILTER,
  payload: status,
})

export const loadFilteringSchedules =
  (dispatchRequest = true) =>
  (dispatch) => {
    if (dispatchRequest) {
      dispatch(
        loadFilteringSchedulesRequest({
          data: [],
        }),
      )
    }

    return loadFilteringSchedulesCall()
      .then((data) =>
        dispatch(
          loadFilteringSchedulesSuccess({
            data,
          }),
        ),
      )
      .catch(() => {
        dispatch(loadFilteringSchedulesFailure())

        dispatch(
          showNotificationWithTTL({
            type: "danger",
            message: "",
            title: "The was a problem while loading filtering schedules",
            icon: "exclamation-circle",
          }),
        )
      })
  }

export const addFilteringSchedule = (data, history) => (dispatch, getState) => {
  dispatch(submittingFilteringSchedule(true))

  return addFilteringScheduleCall(data).then(
    ({ data }) => {
      dispatch(
        showNotificationWithTTL({
          type: "success",
          title: data.attributes.name,
          message: `${data.attributes.name} filtering schedule saved!`,
          icon: "check",
        }),
      )

      dispatch(afterCreateFilteringSchedule(data))
      dispatch(updateAddNewSchedule(false))
    },
    (error) => {
      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: data.attributes.name,
          message: `Failed to save "${data.attributes.name}"`,
        }),
      )

      throw error
    },
  )
}

export const editFilteringSchedule =
  (data, history) => (dispatch, getState) => {
    dispatch(submittingFilteringSchedule(true))

    return updateFilteringScheduleCall(data).then(
      ({ data }) => {
        dispatch(
          showNotificationWithTTL({
            type: "success",
            title: data.attributes.name,
            message: `${data.attributes.name} filtering schedule updated!`,
            icon: "check",
          }),
        )

        dispatch(afterUpdateFilteringSchedule(data))
        dispatch(updateEditExistingSchedule(false))
        dispatch(updateSelectedId(null))
        dispatch(loadFilteringSchedules(false))
      },
      (error) => {
        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: data.attributes.name,
            message: `Failed to update "${data.attributes.name}"`,
          }),
        )

        throw error
      },
    )
  }

export const removeFilteringSchedule = (data, history) => (dispatch) => {
  dispatch(afterRemoveFilteringSchedule(data))
  history.push(createUrl("/policies/schedules"))
}

export function updateAddNewSchedule(payload) {
  return {
    type: UPDATE_ADD_NEW_SCHEDULE,
    payload,
  }
}

export function updateEditExistingSchedule(payload) {
  return {
    type: UPDATE_EDIT_EXISTING_SCHEDULE,
    payload,
  }
}

export const updateDeleteExistingSchedule = (payload) => ({
  type: UPDATE_DELETE_EXISTING_SCHEDULE,
  payload,
})

export function updateSelectedId(payload) {
  return {
    type: UPDATE_SELECTED_ID,
    payload,
  }
}
