import React, { PureComponent, Children } from "react"
import PropTypes from "prop-types"
import axios from "axios"
// import shallowEqual from "fbjs/lib/shallowEqual"
// import omit from "lodash/omit"
import get from "lodash/get"
import isEvent from "utils/isEvent"
import { Block } from "jsxstyle"
import Spinner from "components/Spinner"
import Alert from "components/Alert"
import validateTokenExpDate from "utils/validateTokenExpDate"

const axiosInstance = axios.create()

class Fetch extends PureComponent {
  static propTypes = {
    method: PropTypes.oneOf(["get", "post", "put", "patch", "delete"]),
    url: PropTypes.string.isRequired,
    params: PropTypes.object,
    headers: PropTypes.object,
    body: PropTypes.object,
    withCredentials: PropTypes.bool,
    inline: PropTypes.bool,
    lazy: PropTypes.bool,
    once: PropTypes.bool,
    showAlertOnSuccess: PropTypes.bool,
    successMessage: PropTypes.node,
    showAlertOnError: PropTypes.bool,
    transformError: PropTypes.func.isRequired,
    onResponse: PropTypes.func,
    onData: PropTypes.func,
    onConfirm: PropTypes.func,
    onError: PropTypes.func,
    children: PropTypes.func.isRequired,
  }

  static defaultProps = {
    method: "get",
    showAlertOnError: true,
    transformError: (error) => error,
    children: () => {},
    inline: false,
  }

  state = {
    isFetching: true,
    response: null,
    data: null,
    error: null,
  }

  componentDidMount() {
    if (this.props.lazy) {
      this.hideSpinner()

      return
    }

    this.dispatch(this.props.body)
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.once) {
  //     return
  //   }
  //   if (shallowEqual(nextProps, this.props)) {
  //     return
  //   }
  //   this.reset(() => {
  //     if (nextProps.lazy) {
  //       return
  //     }
  //     this.dispatch(nextProps.body)
  //   })
  // }

  componentWillUnmount() {
    this.willUnmount = true
  }

  getPublicAPI = () => ({
    isFetching: this.state.isFetching,
    response: this.state.response,
    data: this.state.data,
    error: this.state.error,
    getDatum: this.getDatum,
    showSpinner: this.showSpinner,
    hideSpinner: this.hideSpinner,
    dispatch: this.dispatch,
    setError: this.setError,
    reset: this.reset,
  })

  getDatum = (path, defaultValue) => get(this.state.data, path, defaultValue)

  formatResponse = (message) => <span>{message}</span>

  getMessageFromResponse = () => {
    if (!this.state.response) {
      return null
    }

    if (this.state.error && this.state.error.response) {
      const data = this.state.error.response.data
      if (!data) {
        // TODO: send to Rollbar?
        return "Unknown error"
      }

      if (typeof data === "string") {
        return data
      }

      if (typeof data === "object") {
        return data[Object.keys(data)[0]]
      }

      // TODO: send to Rollbar?
      return "Unknown error"
    }

    if (this.state.error instanceof Error) {
      return this.state.error.message
    }

    return this.props.successMessage || this.state.data
  }

  showSpinner = () =>
    this.setState({
      isFetching: true,
    })

  hideSpinner = () =>
    this.setState({
      isFetching: false,
    })

  dispatch = (body = this.props.body, silent = false) => {
    this.setState({
      isFetching: !silent,
      error: null,
    })

    return axiosInstance
      .request({
        method: this.props.method,
        url: this.props.url.match(/^http/)
          ? this.props.url
          : process.env.REACT_APP_API_URL + this.props.url,
        params: this.props.params,
        headers: localStorage.getItem("access_token")
          ? {
              ...this.props.headers,
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            }
          : this.props.headers,
        data: isEvent(body) ? null : body,
        withCredentials: this.props.withCredentials,
      })
      .then((response) => {
        if (this.willUnmount) {
          return
        }

        if (this.props.onResponse) {
          this.props.onResponse(null, response.data)
        }

        if (this.props.onData) {
          this.props.onData(response.data)
        }

        this.setState({
          isFetching: false,
          response,
          data: response.data || {},
        })

        return response.data
      })
      .catch((error) => {
        if (this.willUnmount) {
          return
        }

        if (!validateTokenExpDate(error.response)) return
        const transformedError = this.props.transformError(error, body)
        if (this.props.onResponse) {
          this.props.onResponse(transformedError, null)
        }

        if (this.props.onError) {
          this.props.onError(transformedError)
        }

        this.setState({
          isFetching: false,
          response: transformedError,
          error: transformedError,
        })

        return transformedError
      })
  }

  setError = (error) =>
    this.setState({
      isFetching: false,
      response: error,
      error,
    })

  reset = (callback) =>
    this.setState(
      {
        isFetching: false,
        response: null,
        data: null,
        error: null,
      },
      typeof callback === "function" ? callback : null,
    )

  handleConfirm = () => {
    if (!this.props.onConfirm) {
      this.reset()

      return
    }

    this.props.onConfirm(this.getPublicAPI())
  }

  render() {
    const children = this.props.children(this.getPublicAPI())
    if (!children) {
      return null
    }

    return (
      <Block position="relative">
        <Block
          minHeight={this.state.isFetching && (this.props.inline ? 24 : 90)}
          visibility={this.state.isFetching && "hidden"}
        >
          {Children.only(children)}
        </Block>
        {this.state.isFetching && (
          <Block
            position="absolute"
            top="50%"
            left={this.props.inline ? 0 : "50%"}
            transform={`translate(${this.props.inline ? "0" : "-50%"}, -50%)`}
          >
            <Spinner small={this.props.inline} />
          </Block>
        )}
        {!this.state.isFetching &&
          (this.props.showAlertOnSuccess ||
            (this.props.showAlertOnError && this.state.error)) && (
            <Alert
              intent={this.state.data ? "success" : "danger"}
              isOpen={!!(this.state.data || this.state.error)}
              icon={this.state.data ? "tick" : "error"}
              onConfirm={this.state.data ? this.handleConfirm : this.reset}
            >
              {this.formatResponse(this.getMessageFromResponse())}
            </Alert>
          )}
      </Block>
    )
  }
}

export default Fetch
