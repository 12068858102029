import React, { useEffect } from "react"
import { connect } from "react-redux"
import * as authActions from "actions/auth"
import Login from "views/auth/Login"
import { Content } from "_v3/Core/Components/Content"
import Spinner from "_v2/Core/Components/Spinner"

function LoginContainer({ status, data, email, login, history }) {
  useEffect(() => {
    if (status === "loginTwoFactor") {
      history.push("/two-factor")
    } else if (status === "accountReactivation") {
      history.push("/account-reactivation")
    } else if (process.env.NODE_ENV !== "development") {
      const iframe = window.parent !== window

      if (iframe) {
        window.location.href = `/?distplatform=${localStorage.getItem(
          "distributor",
        )}`
      } else {
        if (localStorage.getItem("googleConflict")) {
          if (localStorage.getItem("signInWithGoogle")) {
            window.location.href = "/google-login-error"
          } else if (localStorage.getItem("signUpWithGoogle")) {
            window.location.href = "/google-signup-error"
          } else {
            window.location.href = "/login"
          }
        } else {
          window.location.href = "/login"
        }
      }
    }
  }, [history, status])

  if (process.env.NODE_ENV !== "development") {
    return (
      <Content>
        <Spinner size={6} />
      </Content>
    )
  }

  return (
    <Login
      status={status}
      messageError={data}
      initialValues={{
        email: email || "",
        password: "",
      }}
      onSubmit={login}
    />
  )
}

const mapStateToProps = (state, props) => ({
  data: state.auth.data,
  email: state.auth.email,
  status: state.auth.status,
  statusMessage: state.auth.statusMessage,
  history: props.history,
})

const mapDispatchToProps = {
  login: authActions.login,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
