import jwtDecode from "jwt-decode"
import { AVATARS_URL } from "consts"

import { DISTPLATFORM_TEMPLATES } from "_Infinity/DistributorPlatform/constants"

const MSP = "msp"

export const identifyHotjar = (data) => {
  if (localStorage.getItem("whitelabel.name")) {
    return
  }

  if (!data.user) {
    return
  }

  if (!window.hj) {
    return
  }

  window.hj("tagRecording", ["app"])
}

export const getDefaultOrganization = (organizations) => {
  const mspOrganization = organizations.find(
    (org) => org.feature_flags.includes("msp") && !org.canceled,
  )
  const defaultOrganization = organizations.find((org) => !org.canceled)

  return mspOrganization || defaultOrganization
}

export const identifyRollbar = (data) => {
  if (localStorage.getItem("whitelabel.name")) {
    return
  }

  if (!data) {
    return
  }

  if (process.env.NODE_ENV === "development") {
    return
  }

  if (!window.rollbar) {
    return
  }

  window.rollbar.configure({
    payload: {
      person: data.user,
    },
    autoInstrument: true,
  })
}

export const identifyDrift = (data) => {
  if (!data.user) {
    return
  }

  let hasLiveChat = false

  for (let k = 0; k < data.organizations.length; k += 1) {
    if (
      !!data.organizations[k].trial_days &&
      !data.organizations[k].managed_by_msp_id
    ) {
      hasLiveChat = true

      break
    }
  }

  if (!hasLiveChat) {
    return
  }

  if (hasLiveChat && window.dataLayer) {
    // window.dataLayer.push({
    //   event: "launchHelpscoutBeaconChat",
    // })
  }
}

export const identifyCanny = (data) => {
  if (!data.user) {
    return
  }

  if (window.Canny && process.env.REACT_APP_CANNY_ID) {
    window.Canny("identify", {
      appID: process.env.REACT_APP_CANNY_ID,
      user: {
        avatarURL: localStorage.getItem("userPicture"),
        email: data.user.email,
        id: String(data.user.id),
        name: data.user.first_name,
        companies: data.organizations?.map((organization) => ({
          id: organization.id,
          name: organization.name,
          monthlySpend:
            organization.current_mrr > 0 ? organization.current_mrr / 100 : 0,
        })),
      },
    })
  }
}

// export const initHelpScout = (data) => {
//   if (
//     !localStorage.getItem("user.isMSP") &&
//     localStorage.getItem("organization.isMSPSubAccount")
//   ) {
//     return
//   }

//   if (!data) {
//     return
//   }

//   if (!window.Beacon) {
//     return
//   }

//   if (window.Beacon?.readyQueue) {
//     window.Beacon.readyQueue = window.Beacon.readyQueue.filter(
//       (item) => item.method !== "destroy"
//     )
//   }

//   window.Beacon("init", "bf6be5aa-92c6-4bb8-b283-811fb77efa8e")

//   window.Beacon("on", "ready", () => {
//     window.Beacon("identify", {
//       email: data.user.email,
//       "Email Verified": data.user.email_verified ? "yes" : "no",
//       "Trial Status": data.organizations.find(
//         ({ id }) => id === Number(localStorage.getItem("orgId"))
//       )?.trial_days
//         ? "In_Trial"
//         : "Trial_Complete",
//       "Dashboard Ticket": "yes",
//     })
//   })
// }

// export const destroyHelpScout = () => {
//   if (!window.Beacon) {
//     return
//   }

//   window.Beacon("destroy")
// }

export const handleOrganization = ({ organization, data }) => {
  if (data.organizations.filter((org) => org.first_traffic_sent).length) {
    localStorage.setItem("organization.firstTrafficSent", true)
  } else {
    localStorage.removeItem("organization.firstTrafficSent")
  }

  if (organization.feature_flags.indexOf(MSP) !== -1) {
    localStorage.setItem("organization.isMSP", true)

    localStorage.setItem(
      "org_ids",
      data.organizations.map((org) => org.id).join(","),
    )
  } else {
    localStorage.removeItem("organization.isMSP")
    localStorage.removeItem("org_ids")
  }

  if (
    organization.feature_flags.indexOf(MSP) === -1 &&
    organization.managed_by_msp_id === null
  ) {
    localStorage.setItem("organization.isStandardOrg", true)
  } else {
    localStorage.removeItem("organization.isStandardOrg")
  }

  if (organization.managed_by_msp_id) {
    localStorage.setItem("organization.isMSPSubAccount", true)
  } else {
    localStorage.removeItem("organization.isMSPSubAccount")
  }

  if (organization.allow_create_network) {
    localStorage.setItem("organization.allowCreateNetwork", true)
  } else {
    localStorage.removeItem("organization.allowCreateNetwork")
  }

  if (organization.show_billing && !organization?.stated_distributor) {
    localStorage.setItem("organization.showBilling", true)
  } else {
    localStorage.removeItem("organization.showBilling")
  }

  if (organization.billing_manual) {
    localStorage.setItem("organization.billingManual", true)
  } else {
    localStorage.removeItem("organization.billingManual")
  }

  if (organization.role === "policies_only") {
    localStorage.setItem("organization.policiesOnly", true)
  } else {
    localStorage.removeItem("organization.policiesOnly")
  }

  // Policies Only should have same behaviour as readOnly for the entire app,
  // except by editing policies
  if (
    organization.role === "read_only" ||
    organization.role === "policies_only"
  ) {
    localStorage.setItem("organization.readOnly", true)
  } else {
    localStorage.removeItem("organization.readOnly")
  }

  // Don't show tools section for users that have only msp sub accounts on their list
  if (
    data.organizations.filter(
      (org) => org.managed_by_msp_id && org.feature_flags.indexOf(MSP) === -1,
    ).length === data.organizations.length
  ) {
    localStorage.removeItem("allowTools")
  } else {
    localStorage.setItem("allowTools", true)
  }

  if (data.organizations.find((org) => org.feature_flags.includes(MSP))) {
    localStorage.setItem("user.isMSP", true)
  } else {
    localStorage.removeItem("user.isMSP")
  }

  localStorage.setItem("orgName", organization.name)
}

export const replacePicture = (firstName, lastName, email, picture) => {
  let userPic = picture
  let initials = ""

  if (firstName && lastName) {
    initials = `${firstName[0]}${lastName[0]}`
  } else if (firstName && !lastName) {
    initials = `${firstName[0]}${firstName[1]}`
  } else {
    initials = `${email[0]}${email[1]}`
  }

  if (!userPic) {
    userPic = `${AVATARS_URL}${initials.toLowerCase()}.png`
  }

  return (
    userPic.substring(0, userPic.length - 6) +
    initials.toLowerCase() +
    userPic.substring(userPic.length - 4, userPic.length)
  )
}

export const isOwnerOrSuperAdmin = ({ user, activeOrganization }) =>
  user?.rolesByOrganization[activeOrganization.id].match(
    /owner|super_administrator/,
  ) ?? false

export const isForcePayment = ({ organizations, auth, initBilling }) => {
  let forcePayment = false

  if (organizations && auth) {
    if (organizations.data && auth.data) {
      let org = organizations.data.find(
        (org) => Number(org.id) === Number(auth.data.organizationId),
      )

      const isManualSubscriptions = org?.feature_flags.includes(
        "manual_subscriptions",
      )

      if (isManualSubscriptions) {
        return false
      }

      forcePayment = org?.will_cancel_at !== null

      if (localStorage.getItem("user.isMSP") && forcePayment) {
        return !initBilling?.orgBillingData?.brand
      }
    }
  }

  return forcePayment
}

export const isOIDCLogin = () => {
  const token = localStorage.getItem("access_token")

  if (!token) {
    return false
  }

  try {
    return jwtDecode(token).sub.startsWith("oidc|")
  } catch {
    return false
  }
}

export const isSocialSignIn = () => {
  const token = localStorage.getItem("access_token")

  if (!token) {
    return false
  }

  try {
    return jwtDecode(token).sub.startsWith("google-oauth2|")
  } catch {
    return false
  }
}

export const isTwoFAEnforcement = ({ organizations, auth }) => {
  if (isOIDCLogin()) {
    return false
  }

  let isTwoFAEnforcement = false

  if (organizations && auth) {
    if (organizations.data && auth.data) {
      const isMfaEnableOrg = !!organizations.data.find(
        (org) => org?.metadata?.mfa_enabled,
      )

      if (isMfaEnableOrg && !auth.data?.mfaEnabled) {
        isTwoFAEnforcement = true
      }
    }
  }

  return isTwoFAEnforcement
}

export const hidePartnerProgramPage = ({ auth }) => {
  const isMSP = localStorage.getItem("user.isMSP") ? true : false
  const isOwner = auth.data.role === "owner"
  const isIndustry = [auth.data?.industry].find(
    (ind) => Number(ind) === 7 || Number(ind) === 18,
  )
    ? true
    : false

  return isIndustry && isOwner && !isMSP ? false : true
}

export const hideAppAwarePage = ({ showAppAwarePlatform, session }) => {
  if (session.activeOrganization.isDistributor) {
    return false
  }

  const msp = session.organizations.filter(
    (org) => org.mspId && !org.ownedByMspId,
  )

  const showAppAwareAcl = session?.user?.acl?.showAppAware
  const showAppAwareMsp = msp ? msp[0]?.showAppAware : false

  let hideAppAware = !(showAppAwareAcl || showAppAwareMsp)

  if (!showAppAwarePlatform) {
    hideAppAware = true
  }

  return hideAppAware
}

export const isOwnerAndSSOEnabled = (session) => {
  const { activeOrganization, user } = session
  const roleUser = user.rolesByOrganization[activeOrganization.id]

  return activeOrganization.ssoEnabled && roleUser === "owner"
}

export const hideAppAwareContent = (session) => {
  if (session.activeOrganization.isDistributor) {
    return false
  }

  const mainOrganization = session.getMainOrganization()
  const platform =
    DISTPLATFORM_TEMPLATES[localStorage.getItem("distributor")] ??
    DISTPLATFORM_TEMPLATES.default
  const plan = session.user?.plan ?? null

  if (!platform?.showAppAwareUpsellBanner) {
    return false
  }

  if (plan === "trial" && session.featureFlags.isAppAwareBeta) {
    return false
  }

  if (!plan && mainOrganization.isAppAwareBeta) {
    return false
  }

  return ["basic", null].includes(session.user.plan)
}
