import React from "react"
import { showNotificationWithTTL } from "actions/notifications"
import loadBlockPagesCall from "api/block-pages/loadBlockPagesCall"
import addBlockPageCall from "api/block-pages/addBlockPageCall"
import removeBlockPageCall from "api/block-pages/removeBlockPageCall"
import updateBlockPageCall from "api/block-pages/updateBlockPageCall"
import {
  LOAD_BLOCK_PAGES,
  UPDATE_BLOCK_PAGES_SEARCH_TEXT,
} from "action-types/blockPages"
import createUrl from "utils/createUrl"

const creatInUseErrorMessage = ({
  method,
  name,
  errorMessage,
  organizations,
}) =>
  errorMessage?.includes("Block page is being used by a MSP customer") &&
  organizations?.length ? (
    <div>
      You can't {method} the {name ? `${name} ` : ""}block page because it is
      currently in use by the following organization
      {organizations.length > 1 ? "s" : ""}:
      <br />
      <br />
      <ul>
        {organizations.map(({ id, name }) => (
          <li key={id}>{name}</li>
        ))}
      </ul>
    </div>
  ) : (
    errorMessage
  )

export const loadBlockPagesRequest = () => ({
  type: LOAD_BLOCK_PAGES,
  payload: {
    data: [],
    status: "requested",
  },
})

export const loadBlockPagesSuccess = (data) => ({
  type: LOAD_BLOCK_PAGES,
  payload: {
    data,
    status: "succeeded",
  },
})

export const loadBlockPagesFailure = () => ({
  type: LOAD_BLOCK_PAGES,
  payload: {
    data: [],
    status: "failed",
  },
})

export const loadBlockPages =
  (dispatchRequest = true) =>
  (dispatch) => {
    if (dispatchRequest) {
      dispatch(loadBlockPagesRequest())
    }

    return loadBlockPagesCall()
      .then((data) => dispatch(loadBlockPagesSuccess(data)))
      .catch(() => dispatch(loadBlockPagesFailure()))
  }

export const addBlockPage = (dispatch, history, platform) => (values) => {
  const iframe = window.parent !== window
  const redirectToDetailsPage = !platform.redirectToBlockPageList && iframe

  return addBlockPageCall(values)
    .then((props) => {
      dispatch(
        showNotificationWithTTL({
          type: "success",
          title: values.name,
          message: `${values.name} block page saved!`,
          icon: "check",
        }),
      )

      return props
    })
    .then(({ data }) => {
      if (redirectToDetailsPage) {
        history.push(
          createUrl(
            `/${
              localStorage.getItem("owned_msp_id")
                ? "global-policies"
                : "policies"
            }/block-pages/${data.id}/edit/details`,
          ),
        )
      } else {
        history.push(
          createUrl(
            `/${
              localStorage.getItem("owned_msp_id")
                ? "global-policies"
                : "policies"
            }/block-pages`,
          ),
        )
      }

      return dispatch(loadBlockPages(false))
    })
    .catch(() =>
      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: values.name,
          message: `Failed to save "${values.name}"`,
        }),
      ),
    )
}

export const updateBlockPage =
  (dispatch, history, id, platform) => (values) => {
    const iframe = window.parent !== window
    const redirectToDetailsPage = !platform.redirectToBlockPageList && iframe

    return updateBlockPageCall(values, id)
      .then((props) => {
        dispatch(
          showNotificationWithTTL({
            type: "success",
            title: values.name,
            message: `${values.name} block page updated!`,
            icon: "check",
          }),
        )

        return props
      })
      .then(({ data }) => {
        if (redirectToDetailsPage) {
          history.push(
            createUrl(
              `/${
                localStorage.getItem("owned_msp_id")
                  ? "global-policies"
                  : "policies"
              }/block-pages/${data.id}/edit/details`,
            ),
          )
        } else {
          history.push(
            createUrl(
              `/${
                localStorage.getItem("owned_msp_id")
                  ? "global-policies"
                  : "policies"
              }/block-pages`,
            ),
          )
        }

        return dispatch(loadBlockPages(false))
      })
      .catch((e) => {
        const { message: errorMessage, organizations } = e
        const { name } = values
        const message = creatInUseErrorMessage({
          method: "disable",
          name,
          errorMessage,
          organizations,
        })
        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: name,
            message,
          }),
        )
      })
  }

export const updateBlockPagesSearchText = (searchText) => ({
  type: UPDATE_BLOCK_PAGES_SEARCH_TEXT,
  payload: searchText,
})

export const removeBlockPage = (dispatch, history, id, values) =>
  removeBlockPageCall(values.id)
    .then(() =>
      dispatch(
        showNotificationWithTTL({
          type: "success",
          title: values.name,
          message: `${values.name} block page removed!`,
          icon: "check",
        }),
      ),
    )
    .then(() => {
      if (localStorage.getItem("owned_msp_id")) {
        history.push(createUrl("/global-policies/block-pages"))
      } else {
        history.push(createUrl("/policies/block-pages"))
      }

      return dispatch(loadBlockPages(false))
    })
    .catch((e) => {
      const { message: errorMessage, organizations } = e
      const { name } = values
      const message = creatInUseErrorMessage({
        method: "delete",
        name,
        errorMessage,
        organizations,
      })
      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: name,
          message,
        }),
      )
    })
