import axios from "axios"
import auth0 from "auth0-js"
import jwtDecode from "jwt-decode"
import queryString from "query-string"
import { differenceInMinutes } from "date-fns"
import { showNotificationWithTTL } from "actions/notifications"
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_TWO_FACTOR,
  LOGOUT,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  GET_USER_PICTURE,
  ACCOUNT_REACTIVATION,
} from "action-types/auth"
import { loadOrganizationsSuccess } from "actions/organization"
import callAPI, { callAPIWithResponse } from "callAPI"
import * as authUtils from "utils/auth"
import isMSPMember from "utils/isMSPMember"
import { trackLogin, trackLogout } from "utils/analytics"
import { cleanupSessionKeys } from "./cleanupSessionKeys"

import { DISTPLATFORM_TEMPLATES } from "_Infinity/DistributorPlatform/constants"
import cookieStorage from "utils/cookieStorage"
import { range } from "lodash"

export const formatOrgs = (orgData) =>
  orgData.map((org) => ({
    ...org.attributes,
    id: parseInt(org.id, 10),
    type: org.attributes.feature_flags.includes("msp")
      ? "msp"
      : org.attributes.managed_by_msp_id
      ? "suborganization"
      : "organization",
  }))

//

let paginatedOrgsCache = null

const getPaginatedOrgs = async () => {
  if (paginatedOrgsCache) {
    return paginatedOrgsCache
  }

  const ITEMS_PER_PAGE = 500

  const firstPage = await callAPI({
    method: "GET",
    path: "/organizations",
    query: {
      "basic_info": true,
      "page[number]": 1,
      "page[size]": 1,
    },
  })

  const numberOfPages = Math.ceil(
    new URL(firstPage.links.last).searchParams.get("page[number]") /
      ITEMS_PER_PAGE,
  )

  const allPages = await Promise.all(
    range(1, numberOfPages + 1).map((page) =>
      callAPI({
        method: "GET",
        path: "/organizations",
        query: {
          "basic_info": true,
          "page[number]": page,
          "page[size]": ITEMS_PER_PAGE,
        },
      }),
    ),
  )

  const allOrganizations = allPages.reduce((acc, v) => [...acc, ...v.data], [])

  paginatedOrgsCache = allOrganizations

  return allOrganizations
}

//

export const getUserAndOrgData = async () => {
  const { user } = await callAPI({
    method: "POST",
    path: "/authenticate",
    query: {
      limit_organizations: 1,
      skip_user_checks: false,
    },
  })
  const paginatedOrgData = await getPaginatedOrgs()
  const organizations = formatOrgs(paginatedOrgData).filter(
    (organization) => !organization.canceled,
  )

  return {
    user,
    organizations,
  }
}

export const getMspData = async (mspId) => {
  const { msp } = await callAPI({
    method: "GET",
    path: `/msps/${mspId}`,
  })

  return msp
}

export function loginRequest() {
  return {
    type: LOGIN_REQUEST,
  }
}

export function loginSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  }
}

export function loginFailure(error, email) {
  return (dispatch) => {
    if (error instanceof Error) {
      return dispatch({
        type: LOGIN_FAILURE,
        payload: error.message,
      })
    }

    const contact = localStorage.getItem("whitelabel.name")
      ? localStorage.getItem("whitelabel.email") || "support"
      : "support@dnsfilter.com"

    let message = null

    // Handle Auth0 errors
    if (error.error === "invalid_user_password") {
      message = "Invalid e-mail or password."
    }

    if (error.error === "unauthorized") {
      message = `Your account is locked. Please contact ${contact} for assistance.`
    }

    if (error.error === "password_leaked" || error.error.match("too_many_")) {
      message = error.error_description
    }

    // Handle API errors
    if (error.error === "Account Canceled") {
      message = `Your account has been cancelled. Please contact ${contact} for assistance.`
    }

    if (error.error === "Account Inactive") {
      message = `Your account has been deactivated. Please contact ${contact} for assistance.`
    }

    if (error.error === "Organizations can only be reactivated by the owner") {
      message = `Organizations can only be reactivated by the owner. Please contact ${contact} for assistance.`
    }

    if (error.error === "Organization is not cancelled") {
      message = `Organization is not cancelled. Please contact ${contact} for assistance.`
    }

    // Handle Auth0 errors
    if (error.error === "invalid_grant") {
      if (error.error_description === "Malformed mfa_token") {
        message = "Invalid token. Please login again."
      }

      if (error.error_description === "Invalid otp_code.") {
        message = "Invalid authenticate code."
      }

      if (error.error_description === "mfa_token is expired.") {
        message = "Session expired. Please login again."
      }

      if (error.error_description === "MFA Authorization rejected.") {
        message = "Invalid recovery code."
      }

      if (error.error_description === "Wrong email or password.") {
        message = "Invalid e-mail or password."
      }
    }

    if (error.error === "invalid_request") {
      message = error.error_description
    }

    if (message) {
      return dispatch({
        type: LOGIN_FAILURE,
        payload: message,
        email,
      })
    }

    if (process.env.NODE_ENV !== "development" && window.Rollbar) {
      window.Rollbar.critical("Login failure.", {
        error,
      })
    }

    return dispatch({
      type: LOGIN_FAILURE,
      payload:
        "An unknown error has occurred, and we notified the support team. Please try again in a few minutes. If the error persists, please contact us.",
    })
  }
}

export const getUserPicture = (pic) => ({
  type: GET_USER_PICTURE,
  payload: pic,
})

async function setUserData(data, dispatch) {
  const { user } = data
  const {
    sub: id,
    picture,
    name,
    email: tokenEmail,
  } = jwtDecode(localStorage.getItem("access_token"))
  const email = tokenEmail || user.email
  const replacedPictureUrl = authUtils.replacePicture(
    user ? user.first_name : email,
    user ? user.last_name : "",
    email,
    picture,
  )
  const organization = authUtils.getDefaultOrganization(data.organizations)
  const userName = user ? user.first_name : user ? user.email : ""
  const userSurname = user ? user.last_name : ""
  const phone = user ? user?.phone : ""
  localStorage.setItem("firstName", userName)
  localStorage.setItem("lastName", userSurname)
  localStorage.setItem("phone", phone)

  if (!localStorage.getItem("owned_msp_id")) {
    localStorage.setItem("orgId", organization.id)
  }

  localStorage.setItem("userId", data.user.id)
  localStorage.setItem("userPicture", replacedPictureUrl)
  authUtils.identifyDrift(data)
  authUtils.identifyRollbar(data)
  authUtils.identifyCanny(data)
  authUtils.identifyHotjar(data)

  if (process.env.REACT_APP_SEGMENT_ID) {
    trackLogin(user)
  }

  if (!organization.id) {
    return dispatch(
      loginFailure({
        error: "Account Inactive",
      }),
    )
  }
  const detailedOrgData = await callAPI({
    method: "GET",
    path: `/organizations/${organization.id}`,
  })
  const orgWithDetails = formatOrgs([detailedOrgData.data])[0]
  authUtils.handleOrganization({
    organization: orgWithDetails,
    data,
  })
  // authUtils.initHelpScout(data)

  let organizationType = ""

  if (organization.feature_flags.indexOf("msp") !== -1) {
    organizationType = "MSP"
  } else if (organization.managed_by_msp_id) {
    organizationType = "MSPSubAccount"
  } else {
    organizationType = "standard"
  }

  submitLoginEvent({
    organizationId: organization.id,
    userId: id,
    tokenLogin: "false",
  })

  try {
    /* Try to set the GTM id of the user to the auth0 sub */
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      event: "loginUserPass",
      userId: user.id,
      intercomVerification: user.intercom_user_verification,
      isMSPMember: isMSPMember(data.organizations),
      organizationType,
    })

    const platform =
      DISTPLATFORM_TEMPLATES[localStorage.getItem("distributor")] ??
      DISTPLATFORM_TEMPLATES.default

    const iframe = window.parent !== window

    if (
      !organization.stated_distributor &&
      !platform.shouldBypassBuiltInSupportBot &&
      !iframe &&
      window.zE &&
      window.zE.identify &&
      user.id &&
      !localStorage.getItem("hideChatSupport") &&
      !localStorage.getItem("zendeskEnabled")
    ) {
      if (!localStorage.getItem("whitelabel.domain")) {
        window.zE("webWidget", "show")
      } else if (
        localStorage.getItem("organization.isMSP") &&
        localStorage.getItem("user.isMSP")
      ) {
        window.zE("webWidget", "show")
      }

      window.zE.identify({
        name:
          user.first_name && user.last_name
            ? `${user.first_name} ${user.last_name}`
            : user.name,
        email: user.email,
        organization: organization.name,
      })
    }

    if (window.userflow && String(user.id) && platform.loadUserflow) {
      window.userflow.identify(String(user.id), {
        name:
          user.first_name && user.last_name
            ? `${user.first_name} ${user.last_name}`
            : user.name,
        email: user.email,
        role: organization.role,
        organization_id: organization.id,
        organization_name: organization.name,
      })
    }
  } catch (err) {}

  dispatch(
    loadOrganizationsSuccess({
      data: data.organizations,
    }),
  )

  dispatch(
    loginSuccess({
      id,
      userId: user.id,
      organizationId: organization.id,
      role: organization.role,
      picture: replacedPictureUrl,
      name,
      email,
      organizationName: organization.name,
      organizationType,
      startDate: data.user.created_at,
      industry: organization.industry || 0,
      revenue: 5 * (organization.stated_network_count || 1),
      mfaEnabled: data.user.mfa_enabled,
    }),
  )
}

export function loginTwoFactorRecovery(
  { recovery_code },
  dispatchRequest = true,
) {
  return (dispatch) => {
    if (dispatchRequest) {
      dispatch(loginRequest())
    }

    const mfa_token = localStorage.getItem("mfa_token")

    return axios({
      method: "post",
      url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
      data: {
        realm: "Username-Password-Authentication",
        grant_type: "http://auth0.com/oauth/grant-type/mfa-recovery-code",
        client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
        mfa_token,
        recovery_code,
      },
    })
      .then(({ data }) => {
        localStorage.setItem("didLogin", true)
        localStorage.removeItem("subscriptionIssuesAlertDismissed")
        localStorage.removeItem("legacyEnterpriseAlertDismissed")
        localStorage.removeItem("billingDetailsAlertDismissed")
        localStorage.removeItem("billingDetailsShownAndClosed")
        localStorage.setItem("access_token", data.access_token)
        localStorage.setItem("refresh_token", data.refresh_token)

        return getUserAndOrgData()
      })
      .then((data) => setUserData(data, dispatch, dispatchRequest))
      .catch(({ response }) => {
        const { data } = response
        dispatch(loginFailure(data, recovery_code))
      })
  }
}

export function loginTwoFactor({ otp_code }, dispatchRequest = true) {
  return (dispatch) => {
    if (dispatchRequest) {
      dispatch(loginRequest())
    }

    const mfa_token = localStorage.getItem("mfa_token")

    return axios({
      method: "post",
      url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
      data: {
        realm: "Username-Password-Authentication",
        grant_type: "http://auth0.com/oauth/grant-type/mfa-otp",
        audience: `https://${process.env.REACT_APP_AUTH0_SUBDOMAIN}.auth0.com/mfa/`,
        client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
        scope:
          "enroll read:authenticators remove:authenticators offline_access",
        mfa_token,
        otp: otp_code,
      },
    })
      .then(({ data }) => {
        localStorage.setItem("didLogin", true)
        localStorage.removeItem("subscriptionIssuesAlertDismissed")
        localStorage.removeItem("legacyEnterpriseAlertDismissed")
        localStorage.removeItem("billingDetailsAlertDismissed")
        localStorage.removeItem("billingDetailsShownAndClosed")
        localStorage.setItem("access_token", data.access_token)
        localStorage.setItem("refresh_token", data.refresh_token)

        return getUserAndOrgData()
      })
      .then((data) => setUserData(data, dispatch, dispatchRequest))
      .catch(({ response, error, organizations }) => {
        if (error === "Account Canceled") {
          if (organizations) {
            localStorage.setItem("reactivation.orgId", organizations[0].id)

            localStorage.setItem(
              "reactivation.token",
              localStorage.getItem("access_token"),
            )

            dispatch({
              type: ACCOUNT_REACTIVATION,
            })
          }
        } else {
          const { data } = response
          dispatch(loginFailure(data, otp_code))
        }
      })
  }
}

export function login({ email, password }, dispatchRequest = true) {
  return (dispatch) => {
    if (dispatchRequest) {
      dispatch(loginRequest())
    }

    return fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        realm: "Username-Password-Authentication",
        grant_type: "http://auth0.com/oauth/grant-type/password-realm",
        username: email,
        password: password,
        audience: `https://${process.env.REACT_APP_AUTH0_SUBDOMAIN}.auth0.com/mfa/`,
        client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
        scope:
          "enroll read:authenticators remove:authenticators offline_access",
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => Promise.reject(data))
        }

        return response.json()
      })
      .then(({ access_token, refresh_token }) => {
        localStorage.setItem("didLogin", "true")
        localStorage.removeItem("subscriptionIssuesAlertDismissed")
        localStorage.removeItem("legacyEnterpriseAlertDismissed")
        localStorage.removeItem("billingDetailsAlertDismissed")
        localStorage.removeItem("billingDetailsShownAndClosed")
        localStorage.setItem("access_token", access_token)
        localStorage.setItem("refresh_token", refresh_token)

        return getUserAndOrgData()
      })
      .then((data) => setUserData(data, dispatch, dispatchRequest))
      .catch((data) => {
        if (data.error === "mfa_required") {
          localStorage.setItem("mfa_token", data.mfa_token)

          verifyMfaToken(data.mfa_token).then((response) => {
            if (response === "otp") {
              return dispatch({
                type: LOGIN_TWO_FACTOR,
              })
            }

            dispatch(loginFailure(response, email))
          })
        } else if (data.error === "Account Canceled") {
          const { organizations } = data
          if (organizations) {
            localStorage.setItem("reactivation.orgId", organizations[0].id)

            localStorage.setItem(
              "reactivation.token",
              localStorage.getItem("access_token"),
            )

            return dispatch({
              type: ACCOUNT_REACTIVATION,
            })
          }

          dispatch(loginFailure(data, email))
        } else {
          dispatch(loginFailure(data, email))
        }
      })
  }
}

// Created so we have a callable sign out function outside of the Redux world.
export function __logout() {
  trackLogout()

  if (window.zE) {
    window.zE("webWidget", "hide")
  }

  cleanupSessionKeys()
}

export function logout() {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
    })

    __logout()
  }
}

export function loginWithToken(
  access_token,
  resetOrgDetails = false,
  distributorPlatform = false,
  lastUrl = "",
) {
  return (dispatch) => {
    localStorage.setItem("access_token", access_token)

    return getUserAndOrgData()
      .then(async (data) => {
        if (resetOrgDetails) {
          localStorage.removeItem("subscriptionIssuesAlertDismissed")
          localStorage.removeItem("legacyEnterpriseAlertDismissed")
          localStorage.removeItem("billingDetailsAlertDismissed")
          localStorage.removeItem("billingDetailsShownAndClosed")
        }

        const { sub: id } = jwtDecode(localStorage.getItem("access_token"))
        let organizationId = resetOrgDetails
          ? null
          : localStorage.getItem("orgId")

        let organization = data.organizations.find(
          (org) => Number(org.id) === Number(organizationId) && !org.canceled,
        )

        const accessToSubscriptions =
          window.location.pathname.includes("billing-info")

        if (
          organization &&
          organization.owned_msp_id &&
          !localStorage.getItem("owned_msp_id") &&
          accessToSubscriptions
        ) {
          localStorage.setItem("owned_msp_id", organization.owned_msp_id)
        }

        if (!organization) {
          organization = authUtils.getDefaultOrganization(data.organizations)
          organizationId = organization.id

          if (!localStorage.getItem("owned_msp_id")) {
            localStorage.setItem("orgId", organizationId)
          }
        }

        if (lastUrl === "login" && organization?.owned_msp_id) {
          localStorage.setItem("owned_msp_id", organization.owned_msp_id)
        }

        // From now on it's safe to check for `organization` attributes.
        // Note: we need to refactor this God function.

        if (organization.gdpr) {
          localStorage.setItem("gdpr", organization.gdpr)
        } else {
          localStorage.removeItem("gdpr")
        }

        if (Number(data.user.id) !== Number(localStorage.getItem("userId"))) {
          const replacedPictureUrl = authUtils.replacePicture(
            data.user.first_name,
            data.user.last_name,
            data.user.email,
          )
          localStorage.setItem("userPicture", replacedPictureUrl)
          localStorage.setItem("userId", data.user.id)
        }

        authUtils.identifyDrift(data)
        authUtils.identifyRollbar(data)
        authUtils.identifyCanny(data)
        authUtils.identifyHotjar(data)

        if (!organizationId) {
          return dispatch(
            loginFailure({
              error: "Account Inactive",
            }),
          )
        }

        const detailedOrgData = await callAPI({
          method: "GET",
          path: `/organizations/${organization.id}`,
        })
        const orgWithDetails = formatOrgs([detailedOrgData.data])[0]
        authUtils.handleOrganization({
          organization: orgWithDetails,
          data,
        })
        // authUtils.initHelpScout(data)

        let organizationType = ""

        if (organization.feature_flags.indexOf("msp") !== -1) {
          organizationType = "MSP"
        } else if (organization.managed_by_msp_id) {
          organizationType = "MSPSubAccount"
        } else {
          organizationType = "standard"
        }

        submitLoginEvent({
          organizationId,
          userId: id,
          tokenLogin: "true",
        })

        try {
          /* Try to set the GTM id of the user to the auth0 sub */
          window.dataLayer = window.dataLayer || []

          window.dataLayer.push({
            event: "loginToken",
            userId: data.user.id,
            intercomVerification: data.user.intercom_user_verification,
            isMSPMember: isMSPMember(data.organizations),
            organizationType,
          })
        } catch (err) {}

        dispatch(
          loadOrganizationsSuccess({
            data: data.organizations,
          }),
        )

        dispatch(
          loginSuccess({
            id,
            organizationId,
            role: organization.role,
            name:
              data.user.first_name && data.user.last_name
                ? `${data.user.first_name} ${data.user.last_name}`
                : data.user.name,
            email: data.user.email,
            orgId: organizationId,
            organizationName: organization.name,
            organizationType,
            startDate: data.user.created_at,
            industry: organization.industry || 0,
            revenue: 5 * (organization.stated_network_count || 1),
            statedDistributor: organization?.stated_distributor ?? null,
            userId: data.user?.id ?? null,
            intercomVerification: data.user?.intercom_user_verification ?? null,
            mfaEnabled: data.user?.mfa_enabled ?? false,
          }),
        )
      })
      .catch((data) => {
        console.error(data)

        if (data.error === "Account Canceled") {
          const { organizations } = data
          if (organizations) {
            localStorage.setItem("reactivation.orgId", organizations[0].id)

            localStorage.setItem(
              "reactivation.token",
              localStorage.getItem("access_token"),
            )

            return dispatch({
              type: ACCOUNT_REACTIVATION,
            })
          }

          if (distributorPlatform) {
            throw new Error(data.error)
          }

          dispatch(logout())
        } else if (data.error) {
          if (distributorPlatform) {
            throw new Error(data.error)
          }

          dispatch(logout())
        }

        if (distributorPlatform) {
          throw new Error("Unknown error")
        }

        return data
      })
  }
}

function submitLoginEvent({ organizationId, userId, tokenLogin }) {
  const utmParams = queryString.parse(
    cookieStorage.getItem("dnsfilterUTMParams"),
  )
  const unbounceParam = cookieStorage.getItem("ub")

  cookieStorage.setItem("account", "1")

  callAPI({
    path: "/user_events/record",
    method: "POST",
    body: {
      user_event: {
        name: "login",
        organization_id: organizationId,
        user_id: userId,
        data: {
          ...utmParams,
          ub: unbounceParam,
          token_login: tokenLogin,
        },
      },
    },
  }).catch((e) => console.error("submitLoginEvent", e))
}

function verifyMfaToken(mfa_token) {
  return axios({
    method: "post",
    url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/mfa/challenge`,
    data: {
      client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
      mfa_token,
      challenge_type: "otp",
    },
  })
    .then(({ data }) => {
      const { challenge_type } = data

      return challenge_type
    })
    .catch(({ response }) => response.data)
}

export const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: `https://${process.env.REACT_APP_AUTH0_SUBDOMAIN}.auth0.com/mfa/`,
  responseType: "token",
  redirectUri:
    typeof window !== "undefined"
      ? `${window.location.origin}/login`
      : undefined,
})

export function refreshToken() {
  const access_token = localStorage.getItem("access_token")

  // User is not logged in
  if (!access_token) {
    return Promise.resolve(null)
  }

  const { exp } = jwtDecode(access_token)

  const minutesLeft = differenceInMinutes(Number(exp) * 1000, new Date())

  if (minutesLeft > 1) {
    return Promise.resolve(access_token)
  }

  if (authUtils.isOIDCLogin()) {
    return new Promise((resolve, reject) => {
      webAuth.checkSession({}, (error, result) => {
        if (error) {
          console.error("[SSO] Failed to renew session", error)

          return reject(new Error(error.error_description))
        }

        const accessToken = result.idToken ?? result.accessToken

        localStorage.setItem("access_token", accessToken)

        resolve(accessToken)
      })
    })
  }

  const refresh_token = localStorage.getItem("refresh_token")

  if (!refresh_token) {
    return Promise.reject("Invalid refresh token")
  }

  return axios({
    method: "post",
    url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
    data: {
      client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
      grant_type: "refresh_token",
      access_token,
      refresh_token,
    },
  }).then(({ data }) => {
    const { access_token, refresh_token } = data

    localStorage.setItem("access_token", access_token)
    localStorage.setItem("refresh_token", refresh_token)

    return access_token
  })
}

export function validateToken() {
  return (dispatch) => {
    dispatch(loginRequest())

    const access_token = localStorage.getItem("access_token")
    const refresh_token = localStorage.getItem("refresh_token")

    if (!access_token) {
      dispatch(loginFailure(new Error("")))

      return
    }

    if (!refresh_token) {
      return callAPIWithResponse({
        method: "POST",
        path: "/authenticate",
        query: {
          limit_organizations: 1,
          skip_user_checks: false,
        },
      })
        .then(() => access_token)
        .catch((error) => {
          if (error.status === 409) {
            localStorage.setItem("googleConflict", "true")
          }

          dispatch(logout(false))

          dispatch(
            loginFailure(new Error("Session expired. Please login again.")),
          )

          return Promise.reject(error)
        })
    }

    return refreshToken().catch((error) => {
      dispatch(logout(false))
      dispatch(loginFailure(new Error("Session expired. Please login again.")))

      return Promise.reject(error)
    })
  }
}

export function changePasswordRequest() {
  return {
    type: CHANGE_PASSWORD_REQUEST,
  }
}

export function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  }
}

export function changePasswordFailure(error) {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload: error,
  }
}

export function changePassword({ email, password }, history) {
  return (dispatch, getState) => {
    dispatch(changePasswordRequest())

    return fetch(`${process.env.REACT_APP_API_URL}/users/reset_password`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email,
        connection: "Username-Password-Authentication",
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => Promise.reject(data))
        }

        return response.text()
      })
      .then(() => {
        dispatch(logout(false))

        dispatch(
          showNotificationWithTTL(
            {
              type: "success",
              title: "Password change request sent!",
              message:
                "Please check your email and click the link to continue the password reset process.",
              icon: "key",
            },
            15,
          ),
        )

        history.push("/")

        return dispatch(changePasswordSuccess())
      })
      .catch((error) => {
        if (error.code === "invalid_user") {
          return dispatch(changePasswordFailure(`${email} is not registered`))
        }

        // TODO: notify DNSFilter team
        return dispatch(
          changePasswordFailure(
            `Failed to change password: ${error.error || error.code}`,
          ),
        )
      })
  }
}
