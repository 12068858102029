import createEntitySchema from "../Utils/createEntitySchema"

let organizationSchema = createEntitySchema(
  "organizations",
  {},
  {
    idAttribute: "id",
    processStrategy: (e) => ({
      id: e.id,
      mspId: e.owned_msp_id,
      ownedByMspId: e.managed_by_msp_id,
      type: e.feature_flags.includes("msp")
        ? "msp"
        : e.managed_by_msp_id
        ? "suborganization"
        : "organization",
      name: e.name,
      trialDaysLeft: Number(e.trial_days),
      showBilling: e.show_billing,
      showActiveDirectory: true, // TODO: e.feature_flags.includes("active_directory"),
      manualSubscriptions: e.feature_flags.includes("manual_subscriptions"),
      allowPrivateIps: e.feature_flags.includes("allow_private_ips"),
      distributorLenovo: e.stated_distributor === "lenovo",
      isDistributor: !!e.stated_distributor,
      distributorSkus: e.distributor_skus,
      canceled: e.canceled,
      subscriptionUserCount: e.subscription_user_count ?? 0,
      subscriptionWifiCount: e.subscription_wifi_count ?? 0,
      subscriptionNfrPlan: e.subscription_nfr_plan ?? "",
      subscriptionNfrTerm: e.subscription_nfr_term ?? "",
      showAppAware: true, //e.feature_flags.includes("appaware_beta"),
      billingAddress: e.billing_address ?? "",
      billingContactName: e.billing_contact_name ?? "",
      mfaEnabled: e.metadata?.mfa_enabled ?? false,
      ssoEnabled: e?.sso_enabled ?? false,
      isAppAwareBeta: e.feature_flags.includes("appaware_beta"),
    }),
  },
)

export default organizationSchema
